import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { isEmpty, propOr } from 'ramda';
import { useTimeout } from 'react-use';

import {
  ANIMATION_DURATION,
  LetterElementType,
  LetterMonogram,
  LetterMonogramMobile,
  MONOGRAM_APPEARANCE_TYPE,
  ANIMATION,
  EXIT_ANIMATION,
} from '@components/monogram/monogram.constants';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { MOBILE } from '@theme/media';
import { renderWhenTrueOtherwise } from '@utils/rendering';
import { MAIN_ANIMATION } from '@utils/constants';
import { MonogramProps } from '@definitions/monogram.types';
import { useMonogram } from '@hooks/useMonogram';
import { useHeaderSublevel } from '@hooks/useUi';
import { useHeaderMobileMenu } from '@hooks/useUi/useUi';

import { Container, LetterElement, LetterElementWrapper } from './monogram.styles';

export interface MonogramTheme {
  type: LetterElementType;
  appearance: MONOGRAM_APPEARANCE_TYPE;
}

export const Monogram = ({ letter, color, id, appearance = MONOGRAM_APPEARANCE_TYPE.FIXED }: MonogramProps) => {
  const { breakpoint } = useBreakpoint();
  const { sublevelOpen } = useHeaderSublevel();
  const { isHeaderMobileMenuOpen } = useHeaderMobileMenu();
  const [isReady] = useTimeout(ANIMATION_DURATION * 1000);
  const { clearMonogram } = useMonogram();
  const isFixed = appearance === MONOGRAM_APPEARANCE_TYPE.SCROLLABLE;
  const firstUppercaseChar = letter
    ? letter.length > 1
      ? letter.charAt(0).toUpperCase()
      : letter.toUpperCase()
    : null;
  const letterMonogramElements = firstUppercaseChar
    ? propOr([], firstUppercaseChar, breakpoint === MOBILE ? LetterMonogramMobile : LetterMonogram)
    : [];

  useEffect(() => {
    if (appearance === MONOGRAM_APPEARANCE_TYPE.DISAPPEAR && isReady()) {
      id && clearMonogram(id);
    }
  }, [isReady()]);

  const renderMonogram = renderWhenTrueOtherwise(
    () => null,
    () => (
      <Container
        fixed={isFixed}
        visible={!sublevelOpen && !isHeaderMobileMenuOpen}
        data-testid={`letter-monogram-${firstUppercaseChar || ''}`}
      >
        {(letterMonogramElements as LetterElementType[]).map((type: LetterElementType) => (
          <ThemeProvider key={type} theme={{ type, appearance } as MonogramTheme}>
            <LetterElementWrapper>
              <LetterElement
                color={color}
                data-testid={type}
                transition={{ duration: MAIN_ANIMATION.DURATION }}
                initial={{ ...EXIT_ANIMATION[type] }}
                animate={{ ...ANIMATION[type] }}
                exit={{ ...EXIT_ANIMATION[type] }}
              />
            </LetterElementWrapper>
          </ThemeProvider>
        ))}
      </Container>
    )
  );

  return renderMonogram(isEmpty(letterMonogramElements));
};
