import { useEffect, useState } from 'react';

import { useWindowListener } from '@hooks/useWindowListener';
import { DISABLE_ACCESSIBILITY_CLASS } from '@utils/constants';

export const useAccessibility = () => {
  const [showAccessibility, setShowAccessibility] = useState(false);

  const removeAccessibilityStyles = () => setShowAccessibility(false);
  const addAccessibilityStyles = (e: KeyboardEvent) => {
    if (e.code === 'Tab') setShowAccessibility(true);
  };

  useEffect(() => {
    if (showAccessibility) {
      document.body.classList.remove(DISABLE_ACCESSIBILITY_CLASS);
      return;
    }

    document.body.classList.add(DISABLE_ACCESSIBILITY_CLASS);
  }, [showAccessibility]);

  useWindowListener('mousedown', removeAccessibilityStyles, {});
  useWindowListener('keydown', addAccessibilityStyles, {});
};
