import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { ArtistsState, ChangeLayoutVariantPayload } from '@definitions/artists.types';

import * as actions from './artists.actions';
import { LAYOUT_VARIANT } from './artists.constants';

export const initialState: ArtistsState = {
  layoutVariant: LAYOUT_VARIANT.GRID,
};

const changeLayoutVariant = (state: ArtistsState, { payload }: PayloadAction<ChangeLayoutVariantPayload>) => {
  state.layoutVariant = payload.layoutVariant;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.changeLayoutVariant, changeLayoutVariant);
});
