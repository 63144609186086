import { createReducer } from '@reduxjs/toolkit';

import { CountriesState, Country, State } from '@definitions/countries.types';

import * as actions from './countries.actions';

export const initialState: CountriesState = { countries: [], states: [] };

const handleFetchedCountries = (state: CountriesState, { payload }: { payload: Country[] }) => {
  state.countries = payload;
};

const handleFetchedStates = (state: CountriesState, { payload }: { payload: State[] }) => {
  state.states = payload;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.getCountries.fulfilled, handleFetchedCountries);
  builder.addCase(actions.getStates.fulfilled, handleFetchedStates);
});
