import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { persistReducer, persistStore, PersistState } from 'redux-persist';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import * as Sentry from '@sentry/react';
// @ts-ignore
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'js-cookie';

import { CmsUserAuthState } from '@definitions/cmsUserAuth.types';
import { CmsUserProfileState } from '@definitions/cmsUserProfile.types';
import { ArtworksState } from '@definitions/artworks.types';
import { FiltersState } from '@definitions/filters.types';
import { CustomerState } from '@definitions/customer.types';
import { UiState } from '@definitions/ui.types';
import { FooterState } from '@definitions/footer.types';
import { MonogramState } from '@definitions/monogram.types';
import { GdprConsentsState } from '@definitions/gdprConsents.types';
import { CountriesState } from '@definitions/countries.types';
import { ShippingState } from '@definitions/shipping.types';
import { OrdersState } from '@definitions/order.types';
import { ShoppingCartState } from '@definitions/shoppingCart.types';
import { GiftCertificateState } from '@definitions/giftCertificate.types';
import { ArtistsState } from '@definitions/artists.types';
import { HeaderState } from '@definitions/header.types';
import { GalleryDocumentsState } from '@definitions/galleryDocuments.types';
//<-- IMPORT STATE -->
import { COOKIE_KEY_POSTFIX } from '@utils/constants';

import { initialState as cmsUserAuthState, reducer as cmsUserAuthReducer } from './cmsUserAuth';
import { initialState as cmsUserProfileState, reducer as cmsUserProfileReducer } from './cmsUserProfile';
import { reducer as artworksReducer } from './artworks';
import { reducer as tradeArtworksReducer } from './tradeArtworks';
import { reducer as groupingArtworksReducer } from './groupingArtworks';
import { initialState as filtersState, reducer as filtersReducer } from './filters';
import { initialState as customerState, reducer as customerReducer } from './customer';
import { initialState as uiState, reducer as uiReducer } from './ui';
import { initialState as monogramState, reducer as monogramReducer } from './monogram';
import { initialState as footerState, reducer as footerReducer } from './footer';
import { initialState as gdprConsentsState, reducer as gdprConsentsReducer } from './gdprConsents';
import { initialState as countriesState, reducer as countriesReducer } from './countries';
import { initialState as shippingState, reducer as shippingReducer } from './shipping';
import { initialState as ordersState, reducer as ordersReducer } from './orders';
import { initialState as shoppingCartState, reducer as shoppingCartReducer } from './shoppingCart';
import { initialState as giftCertificateState, reducer as giftCertificateReducer } from './giftCertificate';
import { initialState as artistsState, reducer as artistsReducer } from './artists';
import { initialState as headerState, reducer as headerReducer } from './header';
import { initialState as plpInitialState } from './plp';
import { initialState as galleryDocuemntsState, reducer as galleryDocuemntsReducer } from './galleryDocuments';
//<-- IMPORT MODULE -->

export type GlobalState = {
  cmsUserAuth: CmsUserAuthState;
  cmsUserProfile: CmsUserProfileState;
  artworks: ArtworksState;
  tradeArtworks: ArtworksState;
  groupingArtworks: ArtworksState;
  filters: FiltersState;
  customer: CustomerState;
  ui: UiState;
  monogram: MonogramState;
  footer: FooterState;
  gdprConsents: GdprConsentsState;
  countries: CountriesState;
  shipping: ShippingState;
  _persist: PersistState;
  orders: OrdersState;
  shoppingCart: ShoppingCartState;
  giftCertificate: GiftCertificateState;
  artists: ArtistsState;
  header: HeaderState;
  galleryDocuments: GalleryDocumentsState;
  //<-- INJECT MODULE STATE TYPE -->
};

export const reducer = combineReducers({
  cmsUserAuth: cmsUserAuthReducer,
  cmsUserProfile: cmsUserProfileReducer,
  artworks: artworksReducer,
  tradeArtworks: tradeArtworksReducer,
  groupingArtworks: groupingArtworksReducer,
  filters: filtersReducer,
  customer: customerReducer,
  ui: uiReducer,
  monogram: monogramReducer,
  footer: footerReducer,
  gdprConsents: gdprConsentsReducer,
  countries: countriesReducer,
  shipping: shippingReducer,
  orders: ordersReducer,
  shoppingCart: shoppingCartReducer,
  giftCertificate: giftCertificateReducer,
  artists: artistsReducer,
  header: headerReducer,
  galleryDocuments: galleryDocuemntsReducer,
  //<-- INJECT REDUCER -->
});

export const preloadedState = {
  cmsUserAuth: cmsUserAuthState,
  cmsUserProfile: cmsUserProfileState,
  artworks: plpInitialState,
  tradeArtworks: plpInitialState,
  groupingArtworks: plpInitialState,
  filters: filtersState,
  customer: customerState,
  ui: uiState,
  monogram: monogramState,
  footer: footerState,
  gdprConsents: gdprConsentsState,
  countries: countriesState,
  shipping: shippingState,
  orders: ordersState,
  shoppingCart: shoppingCartState,
  giftCertificate: giftCertificateState,
  artists: artistsState,
  header: headerState,
  galleryDocuments: galleryDocuemntsState,
  //<-- INJECT STATE -->
};

export const rehydratePreloadedState = {
  ...preloadedState,
  _persist: {
    rehydrated: true,
    version: 1,
  },
};

const shoppingCartFilter = createWhitelistFilter('shoppingCart', ['cartId']);

const persistConfig = {
  key: `root${COOKIE_KEY_POSTFIX}`,
  storage: new CookieStorage(Cookies, {
    setCookieOptions: {
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      secure: process.env.NEXT_PUBLIC_COOKIE_SECURE || true,
      SameSite: process.env.NEXT_PUBLIC_COOKIE_SAME_SITE || 'Lex',
    },
    indexKey: `reduxPersistIndex${COOKIE_KEY_POSTFIX}`,
  }),
  whitelist: ['cmsUserAuth', 'gdprConsents', 'shipping', 'shoppingCart'],
  transforms: [shoppingCartFilter],
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const persistedReducer = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persistedReducer,
  preloadedState,
  devTools: process.env.NODE_ENV === 'development',
  middleware: [thunk],
  enhancers: [sentryReduxEnhancer],
});
// @ts-ignore
export const createPersistStore = () => persistStore(store, { manualPersist: true });
