import React, { Fragment } from 'react';
import { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-intl';
import 'normalize.css/normalize.css';
import { useEffectOnce } from 'react-use';
import { CommerceProvider } from '@bigcommerce/storefront-data-hooks';
import { AnimatePresence } from 'framer-motion';
import { always, includes } from 'ramda';
import Modal from 'react-modal';

import { store, createPersistStore } from '@modules/createStore';
import { DEFAULT_LOCALE, translationMessages } from '@utils/i18';
import { GlobalStyle } from '@theme/globalStyles';
import { ROUTE } from '@utils/constants';
import { renderWhenTrueOtherwise } from '@utils/rendering';
import MonogramContainer from '@components/monogramContainer/monogramContainer';
import { isMaintenanceMode } from '@utils/helpers';
import { CookieBanner } from '@components/cookieBanner';
import { useWindowListener } from '@hooks/useWindowListener';
import { useAccessibility } from '@hooks/useAccessibility';

export default function App({ Component, pageProps, router }: AppProps) {
  const { LOGOUT, GALLERY, ADMIN_PREVIEW } = ROUTE;
  const hasPageTransition = !includes(router.route, [LOGOUT, GALLERY, ADMIN_PREVIEW]);
  const persistor = createPersistStore();
  Modal.setAppElement('#__next');

  const renderAnimatePresence = renderWhenTrueOtherwise(
    always(
      <AnimatePresence exitBeforeEnter={!isMaintenanceMode}>
        <Component {...pageProps} key={router.route} />
      </AnimatePresence>
    ),
    always(<Component {...pageProps} key={router.route} />)
  );

  const setViewportHeight = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
  };

  useEffectOnce(() => {
    setViewportHeight();
  });
  useWindowListener('resize', setViewportHeight, { throttle: 200 });
  useAccessibility();

  return (
    <Fragment>
      <GlobalStyle />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {() => (
            <IntlProvider locale={DEFAULT_LOCALE} messages={translationMessages[DEFAULT_LOCALE]}>
              <CommerceProvider locale="en-US">
                <MonogramContainer />
                {renderAnimatePresence(hasPageTransition)}
                <CookieBanner persistor={persistor} />
              </CommerceProvider>
            </IntlProvider>
          )}
        </PersistGate>
      </Provider>
    </Fragment>
  );
}
