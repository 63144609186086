import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { GiftCertificate, GiftCertificateState } from '@definitions/giftCertificate.types';

import * as actions from './giftCertificate.actions';

export const initialState: GiftCertificateState = {
  giftCertificate: {
    balance: '',
  },
};

const handleCheckBalance = (state: GiftCertificateState, { payload }: PayloadAction<GiftCertificate>) => {
  state.giftCertificate = payload;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.checkBalance.fulfilled, handleCheckBalance);
});
