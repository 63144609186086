import React, { ChangeEvent, useRef, useState } from 'react';
import { always } from 'ramda';
import { FormattedMessage, useIntl, MessageDescriptor } from 'react-intl';
import { UseFormRegisterReturn } from 'react-hook-form';

import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { cookieMessages } from '@components/cookieBanner/cookieBanner.messages';

import {
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  AccordionDescription,
  AccordionHeader,
  AccordionPlus,
  AccordionAction,
  AlwaysOn,
  Checkbox,
} from './cookieBanner.styles';

interface CookieAccordionItemProps {
  title: string;
  description: string;
  alwaysOn?: boolean;
  value?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  register?: UseFormRegisterReturn;
}

export const CookieAccordionItem = ({
  title,
  description,
  alwaysOn = false,
  value = false,
  register,
}: CookieAccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const descriptionRef = useRef<HTMLDivElement>(null);
  const getExpandCopy: (value: boolean) => MessageDescriptor = renderWhenTrueOtherwise(
    always(cookieMessages.collapse),
    always(cookieMessages.expand)
  );
  const getAllowCopy: (value: boolean) => MessageDescriptor = renderWhenTrueOtherwise(
    always(cookieMessages.decline),
    always(cookieMessages.allow)
  );

  const renderAlwaysOn = renderWhenTrue(
    always(
      <AlwaysOn>
        <FormattedMessage id="cookieAccordion.alwaysOn" defaultMessage="Always on" />
      </AlwaysOn>
    )
  );
  const renderCheckbox = renderWhenTrue(
    always(
      <Checkbox
        type="checkbox"
        id={register?.name}
        data-testid={register?.name}
        aria-label={`${intl.formatMessage(getAllowCopy(value))} ${title}`}
        {...register}
      />
    )
  );

  return (
    <AccordionItem>
      <AccordionHeader>
        <AccordionAction
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          aria-label={`${intl.formatMessage(getExpandCopy(isOpen))} ${title}`}
        >
          <AccordionPlus isOpen={isOpen} />
          <AccordionTitle>{title}</AccordionTitle>
        </AccordionAction>
        {renderAlwaysOn(alwaysOn)}
        {renderCheckbox(!!register?.name)}
      </AccordionHeader>
      <AccordionBody height={descriptionRef.current?.offsetHeight || 0} isOpen={isOpen}>
        <AccordionDescription ref={descriptionRef}>{description}</AccordionDescription>
      </AccordionBody>
    </AccordionItem>
  );
};
