import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
  EXPORT_STATUS,
  ExportDocumentsPayload,
  FetchExportStatusPayload,
  FetchOrderNumbersPayload,
  FetchProductsPayload,
  FetchShippingMethodsPayload,
  GalleryDocumentsState,
} from '@definitions/galleryDocuments.types';

import * as actions from './galleryDocuments.actions';

export const initialState: GalleryDocumentsState = {
  autocompleteArtworks: [],
  shippingMethods: [],
  orderNumbers: [],
  isLoading: false,
  exportId: undefined,
  exportStatus: undefined,
};

const handleFetchProducts = (state: GalleryDocumentsState, { payload }: PayloadAction<FetchProductsPayload>) => {
  state.autocompleteArtworks = payload;
  state.isLoading = false;
};

const handleFetchOrderNumbers = (
  state: GalleryDocumentsState,
  { payload }: PayloadAction<FetchOrderNumbersPayload>
) => {
  state.orderNumbers = payload;
  state.isLoading = false;
};

const handleFetchShippingMethods = (
  state: GalleryDocumentsState,
  { payload }: PayloadAction<FetchShippingMethodsPayload>
) => {
  state.shippingMethods = payload;
  state.isLoading = false;
};

const handleFetchExportId = (state: GalleryDocumentsState, { payload }: PayloadAction<ExportDocumentsPayload>) => {
  state.exportId = payload.id;
  state.isLoading = false;
};

const handleFetchExportStatus = (
  state: GalleryDocumentsState,
  { payload }: PayloadAction<FetchExportStatusPayload>
) => {
  state.exportStatus = payload;
  state.isLoading = false;
};

const setLoading = (value: boolean) => (state: GalleryDocumentsState) => {
  state.isLoading = value;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchShippingMethods.pending, setLoading(true));
  builder.addCase(actions.fetchShippingMethods.fulfilled, handleFetchShippingMethods);

  builder.addCase(actions.fetchProductsAutocomplete.pending, setLoading(true));
  builder.addCase(actions.fetchProductsAutocomplete.fulfilled, handleFetchProducts);

  builder.addCase(actions.fetchOrderNumbers.pending, setLoading(true));
  builder.addCase(actions.fetchOrderNumbers.fulfilled, handleFetchOrderNumbers);

  builder.addCase(actions.fetchExportStatus.pending, setLoading(true));
  builder.addCase(actions.fetchExportStatus.fulfilled, handleFetchExportStatus);

  builder.addCase(actions.exportDocuments.pending, setLoading(true));
  builder.addCase(actions.exportDocuments.fulfilled, handleFetchExportId);
  builder.addCase(actions.exportDocuments.rejected, setLoading(false));
});
