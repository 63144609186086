import { createSelector } from '@reduxjs/toolkit';

import { GiftCertificateState } from '@definitions/giftCertificate.types';

import { GlobalState } from '../createStore';

export const selectGiftCertificateDomain = (state: GlobalState) => state.giftCertificate;

export const selectGiftCertificate = createSelector(
  selectGiftCertificateDomain,
  (state: GiftCertificateState) => state.giftCertificate
);
