import styled from 'styled-components';

import { media } from '@theme/media';
import { Z_INDEX } from '@utils/constants';
import { COLORS } from '@theme/colors';
import { FONT_WEIGHT, LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const CookieInformation = styled.div`
  ${typography({ spacing: LETTER_SPACING.S, weight: FONT_WEIGHT.REGULAR })};
  align-self: center;
  text-align: center;

  ${media.tablet} {
    margin: 0 28px;
  }
`;

export const PrivacyLink = styled.a`
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${OFFSET.S};
  text-align: center;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;

  ${media.tablet} {
    margin-top: ${OFFSET.M};
    flex-direction: row;
  }
`;

export const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: `1px solid ${COLORS.BLACK}`,
    borderRadius: '0',
  },
  overlay: {
    zIndex: Z_INDEX.COOKIE_BANNER,
    backgroundColor: COLORS.BLACK50,
  },
};

export const SettingsTitle = styled.h3`
  ${typography({ spacing: LETTER_SPACING.S })};
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 ${OFFSET.M};
  position: relative;
`;

export const ArrowContainer = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  left: 0;
  width: 15px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
`;

export const AccordionAction = styled.button`
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  width: 100%;
`;

export const CookieAccordion = styled.div`
  border-bottom: 1px solid ${COLORS.BLACK};
`;

export const AccordionItem = styled.div`
  border-top: 1px solid ${COLORS.BLACK};
  width: 506px;

  max-width: 100%;
`;

export const AccordionTitle = styled.span`
  width: 100%;
`;

export const AccordionBody = styled.div<{ height: number; isOpen: boolean }>`
  height: ${({ isOpen, height }) => (isOpen ? height : 0)}px;
  transition: height 200ms ease-in-out;
  overflow: hidden;
`;

export const AccordionDescription = styled.div`
  ${typography({ spacing: LETTER_SPACING.S })};
  padding: ${OFFSET.S} 0 ${OFFSET.M};
`;

export const AccordionHeader = styled.div`
  ${typography({ spacing: LETTER_SPACING.S })};
  display: flex;
  align-items: center;
  height: ${OFFSET.M};
`;

export const AccordionPlus = styled.div<{ isOpen: boolean }>`
  width: 28px;
  height: 11px;
  position: relative;
  padding-right: ${OFFSET.S};
  cursor: pointer;

  &:after {
    display: block;
    content: '';
    width: 1px;
    height: 11px;
    background-color: ${COLORS.BLACK};
    position: absolute;
    left: 5px;
    transform: rotate(${({ isOpen }) => (isOpen ? 90 : 0)}deg);
    transition: transform 200ms ease-in-out;
  }

  &:before {
    display: block;
    content: '';
    width: 1px;
    height: 11px;
    background-color: ${COLORS.BLACK};
    transform: rotate(90deg);
    position: absolute;
    left: 5px;
  }
`;

export const AlwaysOn = styled.div`
  white-space: nowrap;
`;

export const Checkbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid ${COLORS.SILVER_CHALICE};
  box-shadow: none;
  padding: 0;
  width: 24px;
  height: 15px;
  border-radius: 10px;
  flex-shrink: 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    display: block;
    background-color: ${COLORS.SILVER_CHALICE};
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

  &:checked {
    box-shadow: none;
    order: 1;
    border-color: ${COLORS.BLACK};

    &:after {
      transform: translate3d(9px, 0, 0);
      background-color: ${COLORS.BLACK};
    }
  }
`;

export const ModalContent = styled.div`
  max-height: calc(var(--vh) - 20px);
  max-width: calc(100vw - 20px);
  overflow-y: auto;
  padding: ${OFFSET.M} ${OFFSET.S};
`;
