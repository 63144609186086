import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { monogramActions, monogramSelectors } from '@modules/monogram';
import { GlobalState } from '@modules/createStore';
import { MonogramProps } from '@definitions/monogram.types';

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useMonogram = () => {
  const dispatch: AppDispatch = useDispatch();
  const monograms = useSelector(monogramSelectors.selectMonograms);

  const addMonogram = (data: MonogramProps) => {
    dispatch(monogramActions.addMonogram(data));
  };

  const clearMonogram = (id: number) => {
    dispatch(monogramActions.clearMonogram(id));
  };

  return { monograms, addMonogram, clearMonogram };
};
