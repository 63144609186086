import { ROUTE } from '@utils/constants';

export interface CookieForm {
  statisticsCookies: boolean;
  functionalCookies: boolean;
  marketingCookies: boolean;
}

export const STATISTICS_COOKIES = 'statisticsCookies';
export const FUNCTIONAL_COOKIES = 'functionalCookies';
export const MARKETING_COOKIES = 'marketingCookies';

export const EXCLUDED_FROM_TRACKING = [ROUTE.GALLERY, ROUTE.GALLERY_LOGIN, ROUTE.GALLERY_PROFILE];
