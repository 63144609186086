import React, { Fragment } from 'react';
import { AnimatePresence } from 'framer-motion';

import 'normalize.css/normalize.css';

import { useMonogram } from '@hooks/useMonogram';
import { Monogram } from '@components/monogram';
import { MonogramInterface } from '@definitions/monogram.types';

export default function monogramContainer() {
  const { monograms } = useMonogram();

  const renderMonogram = (monogram: MonogramInterface) => <Monogram key={monogram.id} {...monogram} />;

  return (
    <Fragment>
      <AnimatePresence>{monograms.map(renderMonogram)}</AnimatePresence>
    </Fragment>
  );
}
