import React, { Fragment, useState } from 'react';
import { always } from 'ramda';
import { FormattedMessage, useIntl } from 'react-intl';
import NextLink from 'next/link';
import { useDeepCompareEffect, useEffectOnce } from 'react-use';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { Persistor } from 'redux-persist/es/types';
import Cookies from 'js-cookie';

import { useGdprConsents } from '@hooks/useGdprConsents';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { Button } from '@components/form';
import { CONSENT_OPTION, CookieTypes, COOKIE_TYPES } from '@definitions/gdprConsents.types';
import { COOKIE_KEY_POSTFIX, ROUTE } from '@utils/constants';
import { cookieMessages } from '@components/cookieBanner/cookieBanner.messages';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useTrackPageView } from '@hooks/useTrackConversions';
import { EXCLUDED_FROM_TRACKING } from '@components/cookieBanner/cookieBanner.constants';

import ArrowDown from '../../assets/images/arrow-down.svg';

import {
  ButtonContainer,
  CookieAccordion,
  CookieInformation,
  modalStyles,
  PrivacyLink,
  SettingsTitle,
  ModalContent,
  ArrowContainer,
} from './cookieBanner.styles';
import { CookieAccordionItem } from './cookieAccordionItem.component';

declare const process: {
  env: {
    NEXT_PUBLIC_OMNISEND_ID: string;
    NEXT_PUBLIC_KLARNA_CLIENT_ID: string;
    NEXT_PUBLIC_GTM_ID: string;
    NEXT_PUBLIC_GA4_ID: string;
    NEXT_PUBLIC_PINTEREST_ID: string;
    NEXT_PUBLIC_FACEBOOK_PIXEL_ID: string;
  };
};

const OMNISEND_ID = process.env.NEXT_PUBLIC_OMNISEND_ID;
const KLARNA_CLIENT_ID = process.env.NEXT_PUBLIC_KLARNA_CLIENT_ID;
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GA4_ID = process.env.NEXT_PUBLIC_GA4_ID;
const PINTEREST_ID = process.env.NEXT_PUBLIC_PINTEREST_ID;
const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

export interface CookieBannerProps {
  persistor: Pick<Persistor, 'persist'>;
}

export const CookieBanner = ({ persistor }: CookieBannerProps) => {
  const router = useRouter();
  const { isMobile } = useBreakpoint();
  const intl = useIntl();
  const { setConsents, gdprConsent, gdprConsents, showModal } = useGdprConsents();
  const [showDetails, setShowDetails] = useState(false);

  const initTrackingTools = () => {
    if (GTM_ID && !EXCLUDED_FROM_TRACKING.includes(router.route as ROUTE)) {
      TagManager.initialize({ gtmId: GTM_ID });
    }
  };

  useTrackPageView();

  const getShowModal = () => {
    try {
      const cookies = Cookies.get(`persist:root${COOKIE_KEY_POSTFIX}`) || '';
      const { gdprConsents }: { gdprConsents: string } = JSON.parse(cookies);
      const { showModal } = JSON.parse(gdprConsents);

      return showModal;
    } catch (e) {
      return true;
    }
  };

  useEffectOnce(() => {
    if (gdprConsent === CONSENT_OPTION.ALLOW) {
      initTrackingTools();
    }
  });

  useEffectOnce(() => {
    const cookiesShowModal = getShowModal();
    if (!cookiesShowModal) {
      persistor.persist();
    }
  });

  useDeepCompareEffect(() => {
    if (gdprConsents[COOKIE_TYPES.OTHER_COOKIES]) {
      initTrackingTools();
    }
  }, [gdprConsents]);

  const handleAllowClick = () => {
    persistor.persist();
    setConsents({
      [COOKIE_TYPES.PERFORMANCE_COOKIES]: true,
      [COOKIE_TYPES.OTHER_COOKIES]: true,
    });
  };

  const { register, handleSubmit, watch } = useForm<CookieTypes>({
    defaultValues: {
      [COOKIE_TYPES.PERFORMANCE_COOKIES]: true,
      [COOKIE_TYPES.OTHER_COOKIES]: true,
    },
  });

  const onSubmit = (data: CookieTypes) => {
    persistor.persist();
    setConsents(data);
  };

  const handleAfterModalOpen = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleAfterModalClose = () => {
    document.body.style.overflow = 'visible';
  };

  const renderOmnisend = renderWhenTrue(
    always(
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            //OMNISEND-SNIPPET-SOURCE-CODE-V1
            window.omnisend = window.omnisend || [];
            omnisend.push(["accountID", "${OMNISEND_ID}"]);
            omnisend.push(["track", "$pageViewed"]);
            !function(){ var e=document.createElement("script");
            e.type= "text/javascript",e.async=!0,e.src= "https://omnisnippet1.com/inshop/launcher-v2.js"; 
            var t=document.getElementsByTagName("script")[0];t.parentNode.insertBefore(e,t)}();
            `,
        }}
      />
    )
  );

  const renderAffiliateTracking = () => <script src="https://www.dwin1.com/57197.js" type="text/javascript" defer />;

  const renderPinterest = renderWhenTrue(
    always(
      <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                  !function(e){if(!window.pintrk){window.pintrk = function () {
                  window.pintrk.queue.push(Array.prototype.slice.call(arguments))};
                  var  n=window.pintrk;
                  n.queue=[],n.version="3.0";
                  var  t=document.createElement("script");
                  t.async=!0,t.src=e;
                  var  r=document.getElementsByTagName("script")[0];  
                  r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
                  pintrk('load', '${PINTEREST_ID}', {em: '<user_email_address>'});
                  pintrk('page');
              `,
          }}
        />
        <noscript>
          <img
            height="1"
            width="1"
            style={{ display: 'none' }}
            alt=""
            src={`https://ct.pinterest.com/v3/?event=init&tid=${PINTEREST_ID}&pd[em]=<hashed_email_address>&noscript=1"`}
          />
        </noscript>
      </Fragment>
    )
  );

  const renderKlarna = renderWhenTrue(
    always(
      <script
        async
        data-environment="production"
        src="https://js.klarna.com/web-sdk/v1/klarna.js"
        data-client-id={KLARNA_CLIENT_ID}
      />
    )
  );

  const renderOtherScripts = renderWhenTrue(
    always(
      <Head>
        {renderOmnisend(!!OMNISEND_ID)}
        {renderPinterest(!!PINTEREST_ID)}
        {renderKlarna(!!KLARNA_CLIENT_ID)}
        {renderAffiliateTracking()}
      </Head>
    )
  );

  const renderDetailContent = renderWhenTrueOtherwise(
    always(
      <form onSubmit={handleSubmit(onSubmit)}>
        <SettingsTitle>
          <ArrowContainer onClick={() => setShowDetails(false)} aria-label={intl.formatMessage(cookieMessages.back)}>
            <ArrowDown />
          </ArrowContainer>
          <span>
            <FormattedMessage id="cookieBanner.settingsTitle" defaultMessage="Cookie Settings:" />
          </span>
        </SettingsTitle>
        <CookieAccordion>
          <CookieAccordionItem
            title={intl.formatMessage(cookieMessages.strictlyNecessaryCookiesTitle)}
            description={intl.formatMessage(cookieMessages.strictlyNecessaryCookiesDescription)}
            alwaysOn
          />
          <CookieAccordionItem
            title={intl.formatMessage(cookieMessages.performanceCookiesTitle)}
            description={intl.formatMessage(cookieMessages.performanceCookiesDescription)}
            value={watch(COOKIE_TYPES.PERFORMANCE_COOKIES)}
            register={register(COOKIE_TYPES.PERFORMANCE_COOKIES)}
          />
          <CookieAccordionItem
            title={intl.formatMessage(cookieMessages.otherCookiesTitle)}
            description={intl.formatMessage(cookieMessages.otherCookiesDescription)}
            value={watch(COOKIE_TYPES.OTHER_COOKIES)}
            register={register(COOKIE_TYPES.OTHER_COOKIES)}
          />
        </CookieAccordion>
        <ButtonContainer>
          <Button type="submit">
            <FormattedMessage id="cookieBanner.confirm" defaultMessage="Confirm settings" />
          </Button>
          <Button type="button" onClick={handleAllowClick} inverted>
            <FormattedMessage {...cookieMessages.allowCookies} />
          </Button>
        </ButtonContainer>
      </form>
    ),
    always(
      <Fragment>
        <CookieInformation>
          <FormattedMessage
            id="cookieBanner.information"
            defaultMessage="Platform uses cookies to improve your shopping experience."
          />
          <a
            onClick={() => {
              window.history.pushState({}, '', ROUTE.PRIVACY_POLICY);
              window.location.href = ROUTE.PRIVACY_POLICY;
            }}
          >
            <PrivacyLink>
              <FormattedMessage id="cookieBanner.readMore" defaultMessage=" Read more" />
            </PrivacyLink>
          </a>
        </CookieInformation>
        <ButtonContainer>
          <Button onClick={() => setShowDetails(true)} textLink={!isMobile}>
            <FormattedMessage id="cookieBanner.settings" defaultMessage="Settings" />
          </Button>
          <Button onClick={handleAllowClick} inverted={isMobile}>
            <FormattedMessage {...cookieMessages.allowCookies} />
          </Button>
        </ButtonContainer>
      </Fragment>
    )
  );

  const renderModal = always(
    <Modal
      isOpen={showModal && gdprConsent !== CONSENT_OPTION.ALLOW && router.asPath !== ROUTE.PRIVACY_POLICY}
      style={modalStyles}
      onAfterOpen={handleAfterModalOpen}
      onAfterClose={handleAfterModalClose}
    >
      <ModalContent>{renderDetailContent(showDetails)}</ModalContent>
    </Modal>
  );

  return (
    <Fragment>
      {renderOtherScripts(gdprConsent === CONSENT_OPTION.ALLOW || gdprConsents[COOKIE_TYPES.OTHER_COOKIES])}
      {renderModal()}
    </Fragment>
  );
};
