import { createSelector } from '@reduxjs/toolkit';

import { FiltersState } from '@definitions/filters.types';

import { GlobalState } from '../createStore';

export const selectFiltersDomain = (state: GlobalState) => state.filters;

export const selectLoading = createSelector(selectFiltersDomain, (state: FiltersState) => state.loading);

export const selectFilterOptions = createSelector(selectFiltersDomain, (state: FiltersState) => state.filterOptions);
