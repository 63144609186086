import { createSelector } from '@reduxjs/toolkit';

import { OrdersState } from '@definitions/order.types';

import { GlobalState } from '../createStore';

export const selectOrdersDomain = (state: GlobalState) => state.orders;

export const selectLoading = createSelector(selectOrdersDomain, (state: OrdersState) => state.loading);
export const selectOrders = createSelector(selectOrdersDomain, (state: OrdersState) => state.orders);
export const selectMeta = createSelector(selectOrdersDomain, (state: OrdersState) => state.meta);
export const selectOrderDetail = createSelector(selectOrdersDomain, (state: OrdersState) => state.orderDetail);
