import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { DetailedOrder, FetchPayload, OrdersState, SORT_DIRECTION, SORT_TYPES } from '@definitions/order.types';
import { DIRECTION, NOT_SHIPPED, SELECTED_GALLERIES, SHIPPED, SORT, ORDER_ID } from '@modules/orders/orders.constants';

import * as actions from './orders.actions';

export const initialState: OrdersState = {
  orders: [],
  loading: true,
  orderDetail: undefined,
  meta: {
    count: 0,
    hasNext: false,
    page: 1,
    galleries: [],
    [SELECTED_GALLERIES]: [],
    [NOT_SHIPPED]: true,
    [SHIPPED]: true,
    [SORT]: SORT_TYPES.ID,
    [DIRECTION]: SORT_DIRECTION.ASC,
    [ORDER_ID]: '',
  },
};

const handleFetchedOrders = (state: OrdersState, { payload }: { payload: FetchPayload }) => {
  const selectedGalleries = payload[SELECTED_GALLERIES] || [];
  state.loading = false;
  state.orders = payload.results;
  state.meta = {
    galleries: payload.meta?.galleries || [],
    count: payload.count,
    hasNext: !!payload.next,
    page: payload.page,
    [SELECTED_GALLERIES]: typeof selectedGalleries === 'object' ? selectedGalleries : [selectedGalleries],
    [SHIPPED]: payload[SHIPPED],
    [NOT_SHIPPED]: payload[NOT_SHIPPED],
    [SORT]: payload[SORT],
    [DIRECTION]: payload[DIRECTION],
    [ORDER_ID]: payload[ORDER_ID],
  };
};

const setLoading = (value: boolean) => (state: OrdersState) => {
  state.loading = value;
};

const handleOrderDetail = (state: OrdersState, { payload }: { payload: DetailedOrder }) => {
  state.orderDetail = payload;
  state.loading = false;
};

const handlePendingOrders = (state: OrdersState) => {
  state.loading = true;
};

const setEdition = (state: OrdersState, { payload }: PayloadAction<{ edition: number; orderId: string }>) => {
  if (state.orderDetail) {
    state.orderDetail.edition = payload.edition;
  }

  if (state.orders.length) {
    state.orders = state.orders.map((order) =>
      order.orderId === payload.orderId ? { ...order, edition: payload.edition } : order
    );
  }
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchOrders.pending, handlePendingOrders);
  builder.addCase(actions.fetchOrders.fulfilled, handleFetchedOrders);
  builder.addCase(actions.confirmBooking.pending, setLoading(true));
  builder.addCase(actions.confirmBooking.fulfilled, setLoading(false));
  builder.addCase(actions.confirmBooking.rejected, setLoading(false));
  builder.addCase(actions.fetchOrderDetail.fulfilled, handleOrderDetail);
  builder.addCase(actions.uploadImages.pending, setLoading(true));
  builder.addCase(actions.uploadImages.rejected, setLoading(false));
  builder.addCase(actions.removeImage.pending, setLoading(true));
  builder.addCase(actions.removeImage.rejected, setLoading(false));
  builder.addCase(actions.setEdition.fulfilled, setEdition);
});
