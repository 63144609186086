import { createSelector } from '@reduxjs/toolkit';

import { CountriesState } from '@definitions/countries.types';

import { GlobalState } from '../createStore';

export const selectCountriesDomain = (state: GlobalState) => state.countries;

export const selectCountries = createSelector(selectCountriesDomain, (state: CountriesState) => state.countries);
export const selectStates = createSelector(selectCountriesDomain, (state: CountriesState) => state.states);
