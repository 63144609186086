import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ExportDocumentsPayload,
  ExportDocumentsState,
  FetchExportStatusPayload,
  FetchExportStatusState,
  FetchOrderNumbersPayload,
  FetchOrderNumbersState,
  FetchProductsPayload,
  FetchProductsState,
  FetchShippingMethodsPayload,
  FetchShippingMethodsState,
} from '@definitions/galleryDocuments.types';
import reportError from '@utils/reportError';
import api from '@services/api';

const PREFIX = 'GALLERY_DOCUMENTS';

const API_BASE_URL = '/export-documents/filters';

export const fetchShippingMethods = createAsyncThunk<FetchShippingMethodsPayload, FetchShippingMethodsState>(
  `${PREFIX}/FETCH_SHIPPING_METHODS`,
  async ({ artworksIds, minimumId = '', maximumId = '' }) => {
    const { data } = await api.get(`${API_BASE_URL}/orders/shipping-methods/?${artworksIds}${minimumId}${maximumId}`);
    return data;
  }
);

export const fetchProductsAutocomplete = createAsyncThunk<FetchProductsPayload, FetchProductsState>(
  `${PREFIX}/FETCH_PRODUCTS_AUTOCOMPLETE`,
  async ({ inputValue }) => {
    const { data } = await api.get(`${API_BASE_URL}/products/autocomplete/?query=${inputValue}`);
    return data.options;
  }
);

export const fetchOrderNumbers = createAsyncThunk<FetchOrderNumbersPayload, FetchOrderNumbersState>(
  `${PREFIX}/FETCH_ORDER_NUMBERS`,
  async ({ artworksIds }) => {
    const { data } = await api.get(`${API_BASE_URL}/orders/?${artworksIds}`);
    return data;
  }
);

export const exportDocuments = createAsyncThunk<ExportDocumentsPayload, ExportDocumentsState>(
  `${PREFIX}/EXPORT_DOCUMENTS`,
  async ({ artworksIds, shippingOptions, minimumId = '', maximumId = '' }) => {
    try {
      const { data } = await api.post(`/export-documents/?${artworksIds}${shippingOptions}${minimumId}${maximumId}`);
      return data;
    } catch (e: unknown) {
      const error = e as AxiosError;
      if (error.response?.data) {
        reportError(error.response.data.error);
      }
    }
  }
);

export const fetchExportStatus = createAsyncThunk<FetchExportStatusPayload, FetchExportStatusState>(
  `${PREFIX}/FETCH_EXPORT_STATUS`,
  async ({ exportId }) => {
    const { data } = await api.get(`/export-documents/${exportId}/`);
    return data;
  }
);
