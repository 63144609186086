import { createAsyncThunk } from '@reduxjs/toolkit';
import { is } from 'ramda';

import nextAPI from '@services/nextAPI';
import { Country, State } from '@definitions/countries.types';

const PREFIX = 'COUNTRIES';

export const getCountries = createAsyncThunk<Country[], void>(`${PREFIX}/GET_COUNTRIES`, async () => {
  const { data } = await nextAPI.get('/countries');

  return data;
});

export const getStates = createAsyncThunk<State[], number>(`${PREFIX}/GET_STATES`, async (countryId) => {
  const { data } = await nextAPI.get(`/countries/${countryId}/states`);
  if (is(Array, data)) {
    return data;
  }
  return [];
});
