import { createReducer } from '@reduxjs/toolkit';

import { initialState, plpActions } from '@modules/plp';
import { ACTION_PREFIX } from '@modules/plp/plp.constants';
import {
  changeGridSize,
  setInitialArtworks,
  fetchArtworks,
  fetchFavoriteArtworks,
  resetArtworks,
  initialFetchArtworks,
  updateKeyBySlug,
  setArtworkDynamicData,
  setCurrentPage,
} from '@modules/plp/plp.reducer';

const prefix = ACTION_PREFIX.ARTWORKS;

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(plpActions.changeGridSize(prefix), changeGridSize);
  builder.addCase(plpActions.setInitialArtworks(prefix), setInitialArtworks);
  builder.addCase(plpActions.fetchArtworks(prefix).pending, initialFetchArtworks);
  builder.addCase(plpActions.fetchArtworks(prefix).fulfilled, fetchArtworks);
  builder.addCase(plpActions.fetchFavoriteArtworks(prefix).fulfilled, fetchFavoriteArtworks);
  builder.addCase(plpActions.resetArtworks(prefix), resetArtworks);
  builder.addCase(plpActions.setArtworkDynamicData(prefix), setArtworkDynamicData);
  builder.addCase(plpActions.setCurrentPage(prefix), setCurrentPage);
  builder.addCase(plpActions.addToFavorite(prefix).fulfilled, updateKeyBySlug('favorite', true));
  builder.addCase(plpActions.addToWaitlist(prefix).fulfilled, updateKeyBySlug('waitlist', true));
  builder.addCase(plpActions.removeFromFavorite(prefix).fulfilled, updateKeyBySlug('favorite', false));
  builder.addCase(plpActions.removeFromWaitlist(prefix).fulfilled, updateKeyBySlug('waitlist', false));
});
