import { createReducer } from '@reduxjs/toolkit';
import { filter } from 'ramda';

import { MonogramInterface, MonogramProps, MonogramState } from '@definitions/monogram.types';

import * as actions from './monogram.actions';

export const initialState: MonogramState = {
  monograms: [],
};

const switchMonogram = (state: MonogramState, { payload }: { payload: MonogramProps }) => {
  state.monograms = [{ ...payload, id: payload.id ? payload.id : new Date().getTime() }];
};

const clearMonogram = (state: MonogramState, { payload }: { payload: number }) => {
  state.monograms = filter(({ id }: MonogramInterface) => id !== payload, state.monograms);
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.addMonogram, switchMonogram);
  builder.addCase(actions.clearMonogram, clearMonogram);
});
