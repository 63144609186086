import { createAsyncThunk } from '@reduxjs/toolkit';

import { FilterItem } from '@definitions/filters.types';
import api from '@services/api';

const PREFIX = 'FILTERS';

export const fetchFilterOptions = createAsyncThunk(`${PREFIX}/FETCH_FILTER_OPTIONS`, async (groupingName: string) => {
  const { data: filterOptions } = await api.get('/artworks/filters/', {
    params: { grouping: groupingName },
  });

  return { filterOptions };
});

export const fetchTradeFilterOptions = createAsyncThunk(`${PREFIX}/FETCH_TRADE_FILTER_OPTIONS`, async () => {
  const { data: filterOptions } = await api.get('/trade/filters/');

  return { filterOptions };
});
