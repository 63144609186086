import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { motion } from 'framer-motion';

import { COLORS } from '@theme/colors';
import { media } from '@theme/media';
import {
  LetterElementType,
  CONTAINER_MAX_WIDTH,
  ELEMENT_THICKNESS_MOBILE,
  ELEMENT_THICKNESS_DESKTOP,
  HORIZONTAL_ELEMENT_WIDTH,
} from '@components/monogram/monogram.constants';
import { Z_INDEX } from '@utils/constants';

export const Container = styled.div<{ fixed: boolean; visible: boolean }>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  top: 10px;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: ${Z_INDEX.MONOGRAM};
  max-height: calc(var(--vh) - 20px);
  width: calc(100% - 20px);
  max-width: ${CONTAINER_MAX_WIDTH}px;
  margin: 0 auto;
  mix-blend-mode: multiply;
  pointer-events: none;
  transition: opacity 0.6s ease-in-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};

  ${media.tablet} {
    top: ${ELEMENT_THICKNESS_DESKTOP}px;
    bottom: ${ELEMENT_THICKNESS_DESKTOP}px;
    height: calc(100% - ${ELEMENT_THICKNESS_DESKTOP * 2}px);
    max-height: calc(var(--vh) - ${ELEMENT_THICKNESS_DESKTOP * 2}px);
  }
`;

const verticalDirectionStyles = css`
  width: ${ELEMENT_THICKNESS_MOBILE}px;
  height: auto;
  top: ${ELEMENT_THICKNESS_MOBILE * 1.5}px;
  bottom: ${ELEMENT_THICKNESS_MOBILE * 1.5}px;

  ${media.tablet} {
    top: 0;
    bottom: auto;
    height: 100%;
    width: ${ELEMENT_THICKNESS_DESKTOP}px;
  }
`;

const horizontalDirectionStyles = css`
  height: ${ELEMENT_THICKNESS_MOBILE}px;
  width: 100%;

  ${media.tablet} {
    height: ${ELEMENT_THICKNESS_DESKTOP}px;
    width: ${HORIZONTAL_ELEMENT_WIDTH}px;
    left: calc(50% - ${HORIZONTAL_ELEMENT_WIDTH / 2}px);
  }
`;

const topStyles = css`
  ${horizontalDirectionStyles};
  top: 0;
  bottom: auto;
`;

const topShortStyles = css`
  ${topStyles};
  width: calc(100% - ${ELEMENT_THICKNESS_MOBILE * 1.5}px);
`;

const topLeftStyles = css`
  ${topStyles};
  width: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px);

  ${media.tablet} {
    width: calc(${HORIZONTAL_ELEMENT_WIDTH / 2}px - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
  }
`;

const rightStyles = css`
  ${verticalDirectionStyles};
  right: 0;
  left: auto;
`;

const rightTopStyles = css`
  ${rightStyles};
  height: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px - ${ELEMENT_THICKNESS_MOBILE * 1.5}px);

  ${media.tablet} {
    height: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
  }
`;

const rightTopRotateStyles = css`
  ${rightTopStyles};
  transform-origin: 0 0;
  transform: rotate(45deg) translate(${ELEMENT_THICKNESS_DESKTOP / 4}px, -${ELEMENT_THICKNESS_DESKTOP / 4}px);
`;

const rightBottomStyles = css`
  ${rightStyles};
  top: calc(50% + ${ELEMENT_THICKNESS_MOBILE / 2}px);
  height: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px - ${ELEMENT_THICKNESS_MOBILE * 1.5}px);

  ${media.tablet} {
    top: calc(50% + ${ELEMENT_THICKNESS_DESKTOP / 2}px);
    height: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
  }
`;

const rightBottomRotateStyles = css`
  ${rightBottomStyles};
  transform-origin: 100% 100%;
  transform: rotate(-45deg) translate(${ELEMENT_THICKNESS_DESKTOP / 2}px, -${ELEMENT_THICKNESS_DESKTOP / 2}px);
`;

const rightBottomRotateOffsetStyles = css`
  ${rightBottomRotateStyles};
  top: calc(50% + ${ELEMENT_THICKNESS_MOBILE * 2}px);
  transform: rotate(-45deg) translate(${ELEMENT_THICKNESS_DESKTOP / 2}px, -${ELEMENT_THICKNESS_DESKTOP * 3.5}px);
`;

const rightCenterStyles = css`
  ${rightStyles};

  ${media.tablet} {
    top: calc(25% + ${ELEMENT_THICKNESS_DESKTOP / 3}px);
    height: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
  }
`;

const bottomStyles = css`
  ${horizontalDirectionStyles};
  bottom: 0;
  top: auto;
`;

const bottomShortStyles = css`
  ${bottomStyles};
  width: calc(100% - ${ELEMENT_THICKNESS_MOBILE * 1.5}px);
`;

const leftStyles = css`
  ${verticalDirectionStyles};
  left: 0;
  right: auto;
`;

const leftTopStyles = css`
  ${leftStyles};
  height: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px - ${ELEMENT_THICKNESS_MOBILE * 1.5}px);

  ${media.tablet} {
    height: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
  }
`;

const leftBottomStyles = css`
  ${leftStyles};
  top: calc(50% + ${ELEMENT_THICKNESS_MOBILE / 2}px);
  height: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px - ${ELEMENT_THICKNESS_MOBILE * 1.5}px);

  ${media.tablet} {
    top: calc(50% + ${ELEMENT_THICKNESS_DESKTOP / 2}px);
    height: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
  }
`;

const centerHorizontalStyles = css`
  ${horizontalDirectionStyles};
  top: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px);
  width: auto;
  left: ${1.5 * ELEMENT_THICKNESS_MOBILE}px;
  right: ${1.5 * ELEMENT_THICKNESS_MOBILE}px;

  ${media.tablet} {
    top: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
    left: calc(50% - ${HORIZONTAL_ELEMENT_WIDTH / 2}px);
  }
`;

const centerHorizontalRightStyles = css`
  ${horizontalDirectionStyles};
  left: auto;
  top: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px);
  right: ${ELEMENT_THICKNESS_MOBILE * 1.5}px;
  width: calc(60% - ${ELEMENT_THICKNESS_DESKTOP * 3}px);

  ${media.tablet} {
    left: auto;
    width: calc(50% - ${ELEMENT_THICKNESS_DESKTOP * 2.5}px);
    top: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
    right: ${ELEMENT_THICKNESS_DESKTOP * 2}px;
  }
`;

const centerHorizontalLeftStyles = css`
  ${horizontalDirectionStyles};
  top: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px);
  left: ${ELEMENT_THICKNESS_MOBILE * 1.5}px;
  width: calc(60% - ${ELEMENT_THICKNESS_DESKTOP * 3}px);

  ${media.tablet} {
    width: calc(50% - ${ELEMENT_THICKNESS_DESKTOP * 2.5}px);
    top: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
    left: ${ELEMENT_THICKNESS_DESKTOP * 4}px;
  }
`;

const centerVerticalStyles = css`
  ${verticalDirectionStyles};
  left: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px);

  ${media.tablet} {
    top: ${ELEMENT_THICKNESS_DESKTOP * 2}px;
    left: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
    max-height: calc(100% - ${4 * ELEMENT_THICKNESS_DESKTOP}px);
  }
`;

const centerVerticalBottomStyles = css`
  ${verticalDirectionStyles};
  top: auto;
  bottom: ${ELEMENT_THICKNESS_MOBILE * 1.5}px;
  left: calc((100% - ${ELEMENT_THICKNESS_MOBILE}px) / 2);
  height: calc(50% - ${ELEMENT_THICKNESS_MOBILE * 2}px);

  ${media.tablet} {
    top: auto;
    bottom: ${2 * ELEMENT_THICKNESS_DESKTOP}px;
    left: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
    max-height: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
  }
`;

const centerVerticalBottomOffsetStyles = css`
  ${centerVerticalBottomStyles};
  bottom: 0;
  height: calc(50% - ${ELEMENT_THICKNESS_MOBILE}px);

  ${media.tablet} {
    bottom: 0;
    height: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px - ${ELEMENT_THICKNESS_DESKTOP}px);
  }
`;

const centerVerticalShortStyles = css`
  ${verticalDirectionStyles};

  top: calc(25% + ${ELEMENT_THICKNESS_MOBILE}px);
  left: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px);
  height: calc(50% - ${ELEMENT_THICKNESS_MOBILE * 2}px);

  ${media.tablet} {
    top: calc(25% + ${ELEMENT_THICKNESS_DESKTOP / 3}px);
    left: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
    height: calc(50% - ${ELEMENT_THICKNESS_DESKTOP / 2}px);
  }
`;

const centerHorizontalRotateStyles = css`
  ${horizontalDirectionStyles};
  top: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px);
  width: 100%;
  transform: rotate(45deg);

  ${media.tablet} {
    top: calc(50% - ${ELEMENT_THICKNESS_MOBILE / 2}px);
    width: ${HORIZONTAL_ELEMENT_WIDTH}px;
    transform: rotate(45deg);
  }
`;

const centerHorizontalRotateNegativeStyles = css`
  ${centerHorizontalRotateStyles};
  transform: rotate(-45deg);

  ${media.tablet} {
    transform: rotate(-45deg);
  }
`;

const shortRotateStyles = css`
  top: calc(50% - 33vw);
  bottom: auto;
  height: 66vw;

  ${media.tablet} {
    top: ${ELEMENT_THICKNESS_DESKTOP}px;
    height: 72%;
  }
`;

const rightShortRotateStyles = css`
  ${rightStyles};
  ${shortRotateStyles};
  transform-origin: 100% 100%;
  right: 50%;
  transform: rotate(45deg);
`;

const leftShortRotateStyles = css`
  ${leftStyles};
  ${shortRotateStyles};
  left: 50%;
  transform-origin: 0 100%;
  transform: rotate(-45deg);
`;

export const LetterElementWrapper = styled.div`
  position: absolute;

  ${theme('type', {
    [LetterElementType.TOP]: topStyles,
    [LetterElementType.TOP_SHORT]: topShortStyles,
    [LetterElementType.TOP_LEFT]: topLeftStyles,
    [LetterElementType.RIGHT]: rightStyles,
    [LetterElementType.RIGHT_TOP]: rightTopStyles,
    [LetterElementType.RIGHT_TOP_ROTATE]: rightTopRotateStyles,
    [LetterElementType.RIGHT_BOTTOM]: rightBottomStyles,
    [LetterElementType.RIGHT_BOTTOM_ROTATE]: rightBottomRotateStyles,
    [LetterElementType.RIGHT_BOTTOM_ROTATE_OFFSET]: rightBottomRotateOffsetStyles,
    [LetterElementType.RIGHT_CENTER]: rightCenterStyles,
    [LetterElementType.RIGHT_SHORT_ROTATE]: rightShortRotateStyles,
    [LetterElementType.BOTTOM]: bottomStyles,
    [LetterElementType.BOTTOM_SHORT]: bottomShortStyles,
    [LetterElementType.LEFT]: leftStyles,
    [LetterElementType.LEFT_TOP]: leftTopStyles,
    [LetterElementType.LEFT_BOTTOM]: leftBottomStyles,
    [LetterElementType.LEFT_SHORT_ROTATE]: leftShortRotateStyles,
    [LetterElementType.CENTER_HORIZONTAL]: centerHorizontalStyles,
    [LetterElementType.CENTER_HORIZONTAL_RIGHT]: centerHorizontalRightStyles,
    [LetterElementType.CENTER_HORIZONTAL_LEFT]: centerHorizontalLeftStyles,
    [LetterElementType.CENTER_HORIZONTAL_ROTATE]: centerHorizontalRotateStyles,
    [LetterElementType.CENTER_HORIZONTAL_ROTATE_NEGATIVE]: centerHorizontalRotateNegativeStyles,
    [LetterElementType.CENTER_VERTICAL]: centerVerticalStyles,
    [LetterElementType.CENTER_VERTICAL_BOTTOM]: centerVerticalBottomStyles,
    [LetterElementType.CENTER_VERTICAL_BOTTOM_OFFSET]: centerVerticalBottomOffsetStyles,
    [LetterElementType.CENTER_VERTICAL_SHORT]: centerVerticalShortStyles,
  })};
`;

export const LetterElement = styled(motion.div)<{ color?: string; animation?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => (color ? color : COLORS.BLACK)};
  transform-origin: 0 50%;
  animation-fill-mode: forwards;
`;
