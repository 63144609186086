import { NARROW_CONTAINER_WIDTH } from '@components/blocks/blocks.constants';

export const CONTAINER_MAX_WIDTH = 676;
export const HORIZONTAL_ELEMENT_WIDTH = NARROW_CONTAINER_WIDTH;
export const ELEMENT_THICKNESS_MOBILE = 44;
export const ELEMENT_THICKNESS_DESKTOP = 34;
export const ANIMATION_DURATION = 1.6;
export const ANIMATION_DELAY = 0.2;

export enum MONOGRAM_APPEARANCE_TYPE {
  DISAPPEAR = 'disappear',
  SCROLLABLE = 'scrollable',
  FIXED = 'fixed',
}

export enum LetterElementType {
  TOP = 'top',
  TOP_SHORT = 'topShort',
  TOP_LEFT = 'topLeft',
  RIGHT = 'right',
  RIGHT_TOP = 'rightTop',
  RIGHT_TOP_ROTATE = 'rightTopRotate',
  RIGHT_BOTTOM = 'rightBottom',
  RIGHT_BOTTOM_ROTATE = 'rightBottomRotate',
  RIGHT_BOTTOM_ROTATE_OFFSET = 'rightBottomRotateOffset',
  RIGHT_CENTER = 'rightCenter',
  RIGHT_SHORT_ROTATE = 'rightShortRotate',
  BOTTOM = 'bottom',
  BOTTOM_SHORT = 'bottomShort',
  LEFT = 'left',
  LEFT_TOP = 'leftTop',
  LEFT_BOTTOM = 'leftBottom',
  LEFT_SHORT_ROTATE = 'leftShortRotate',
  CENTER_HORIZONTAL = 'centerHorizontal',
  CENTER_HORIZONTAL_RIGHT = 'centerHorizontalRight',
  CENTER_HORIZONTAL_LEFT = 'centerHorizontalLeft',
  CENTER_HORIZONTAL_ROTATE = 'centerHorizontalRotate',
  CENTER_HORIZONTAL_ROTATE_NEGATIVE = 'centerHorizontalRotateNegative',
  CENTER_VERTICAL = 'centerVertical',
  CENTER_VERTICAL_BOTTOM = 'centerVerticalBottom',
  CENTER_VERTICAL_BOTTOM_OFFSET = 'centerVerticalBottomOffset',
  CENTER_VERTICAL_SHORT = 'centerVerticalShort',
}

const horizontalAnimationStyles = {
  scaleX: 1,
};

const verticalAnimationStyles = {
  scaleY: 1,
};

export const ANIMATION = {
  [LetterElementType.TOP]: horizontalAnimationStyles,
  [LetterElementType.TOP_SHORT]: horizontalAnimationStyles,
  [LetterElementType.TOP_LEFT]: horizontalAnimationStyles,
  [LetterElementType.RIGHT]: verticalAnimationStyles,
  [LetterElementType.RIGHT_TOP]: verticalAnimationStyles,
  [LetterElementType.RIGHT_TOP_ROTATE]: verticalAnimationStyles,
  [LetterElementType.RIGHT_BOTTOM]: verticalAnimationStyles,
  [LetterElementType.RIGHT_BOTTOM_ROTATE]: verticalAnimationStyles,
  [LetterElementType.RIGHT_BOTTOM_ROTATE_OFFSET]: verticalAnimationStyles,
  [LetterElementType.RIGHT_CENTER]: verticalAnimationStyles,
  [LetterElementType.RIGHT_SHORT_ROTATE]: verticalAnimationStyles,
  [LetterElementType.BOTTOM]: horizontalAnimationStyles,
  [LetterElementType.BOTTOM_SHORT]: horizontalAnimationStyles,
  [LetterElementType.LEFT]: verticalAnimationStyles,
  [LetterElementType.LEFT_TOP]: verticalAnimationStyles,
  [LetterElementType.LEFT_BOTTOM]: verticalAnimationStyles,
  [LetterElementType.LEFT_SHORT_ROTATE]: verticalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL]: horizontalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL_RIGHT]: horizontalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL_LEFT]: horizontalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL_ROTATE]: horizontalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL_ROTATE_NEGATIVE]: horizontalAnimationStyles,
  [LetterElementType.CENTER_VERTICAL]: verticalAnimationStyles,
  [LetterElementType.CENTER_VERTICAL_BOTTOM]: verticalAnimationStyles,
  [LetterElementType.CENTER_VERTICAL_BOTTOM_OFFSET]: verticalAnimationStyles,
  [LetterElementType.CENTER_VERTICAL_SHORT]: verticalAnimationStyles,
};

const negativeHorizontalAnimationStyles = {
  scaleX: 0,
};

const negativeVerticalAnimationStyles = {
  scaleY: 0,
  transformOrigin: '50% 0',
};

export const EXIT_ANIMATION = {
  [LetterElementType.TOP]: negativeHorizontalAnimationStyles,
  [LetterElementType.TOP_SHORT]: negativeHorizontalAnimationStyles,
  [LetterElementType.TOP_LEFT]: negativeHorizontalAnimationStyles,
  [LetterElementType.RIGHT]: negativeVerticalAnimationStyles,
  [LetterElementType.RIGHT_TOP]: negativeVerticalAnimationStyles,
  [LetterElementType.RIGHT_TOP_ROTATE]: negativeVerticalAnimationStyles,
  [LetterElementType.RIGHT_BOTTOM]: negativeVerticalAnimationStyles,
  [LetterElementType.RIGHT_BOTTOM_ROTATE]: negativeVerticalAnimationStyles,
  [LetterElementType.RIGHT_BOTTOM_ROTATE_OFFSET]: negativeVerticalAnimationStyles,
  [LetterElementType.RIGHT_CENTER]: negativeVerticalAnimationStyles,
  [LetterElementType.RIGHT_SHORT_ROTATE]: negativeVerticalAnimationStyles,
  [LetterElementType.BOTTOM]: negativeHorizontalAnimationStyles,
  [LetterElementType.BOTTOM_SHORT]: negativeHorizontalAnimationStyles,
  [LetterElementType.LEFT]: negativeVerticalAnimationStyles,
  [LetterElementType.LEFT_TOP]: negativeVerticalAnimationStyles,
  [LetterElementType.LEFT_BOTTOM]: negativeVerticalAnimationStyles,
  [LetterElementType.LEFT_SHORT_ROTATE]: negativeVerticalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL]: negativeHorizontalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL_RIGHT]: negativeHorizontalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL_LEFT]: negativeHorizontalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL_ROTATE]: negativeHorizontalAnimationStyles,
  [LetterElementType.CENTER_HORIZONTAL_ROTATE_NEGATIVE]: negativeHorizontalAnimationStyles,
  [LetterElementType.CENTER_VERTICAL]: negativeVerticalAnimationStyles,
  [LetterElementType.CENTER_VERTICAL_BOTTOM]: negativeVerticalAnimationStyles,
  [LetterElementType.CENTER_VERTICAL_BOTTOM_OFFSET]: negativeVerticalAnimationStyles,
  [LetterElementType.CENTER_VERTICAL_SHORT]: negativeVerticalAnimationStyles,
};

export const LetterMonogram = {
  A: [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.CENTER_HORIZONTAL, LetterElementType.RIGHT],
  B: [
    LetterElementType.LEFT,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT_TOP,
    LetterElementType.RIGHT_BOTTOM,
  ],
  C: [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.BOTTOM],
  D: [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.BOTTOM, LetterElementType.RIGHT_CENTER],
  E: [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.CENTER_HORIZONTAL, LetterElementType.BOTTOM],
  F: [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.CENTER_HORIZONTAL],
  G: [
    LetterElementType.LEFT,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL_RIGHT,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT_BOTTOM,
  ],
  H: [LetterElementType.LEFT, LetterElementType.CENTER_HORIZONTAL, LetterElementType.RIGHT],
  I: [LetterElementType.TOP, LetterElementType.CENTER_VERTICAL_SHORT, LetterElementType.BOTTOM],
  J: [LetterElementType.LEFT_BOTTOM, LetterElementType.TOP, LetterElementType.BOTTOM, LetterElementType.RIGHT],
  K: [
    LetterElementType.LEFT,
    LetterElementType.CENTER_HORIZONTAL_LEFT,
    LetterElementType.RIGHT_TOP_ROTATE,
    LetterElementType.RIGHT_BOTTOM_ROTATE,
  ],
  L: [LetterElementType.LEFT, LetterElementType.BOTTOM],
  M: [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.CENTER_VERTICAL, LetterElementType.RIGHT],
  N: [LetterElementType.LEFT, LetterElementType.CENTER_HORIZONTAL_ROTATE, LetterElementType.RIGHT],
  O: [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.BOTTOM, LetterElementType.RIGHT],
  P: [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.CENTER_HORIZONTAL, LetterElementType.RIGHT_TOP],
  Q: [
    LetterElementType.LEFT,
    LetterElementType.TOP,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT,
    LetterElementType.RIGHT_BOTTOM_ROTATE_OFFSET,
  ],
  R: [
    LetterElementType.LEFT,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.RIGHT_TOP,
    LetterElementType.RIGHT_BOTTOM_ROTATE,
  ],
  S: [
    LetterElementType.LEFT_TOP,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT_BOTTOM,
  ],
  T: [LetterElementType.TOP, LetterElementType.CENTER_VERTICAL],
  U: [LetterElementType.LEFT, LetterElementType.BOTTOM, LetterElementType.RIGHT],
  V: [LetterElementType.LEFT_SHORT_ROTATE, LetterElementType.RIGHT_SHORT_ROTATE],
  W: [
    LetterElementType.LEFT,
    LetterElementType.CENTER_VERTICAL_BOTTOM,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT,
  ],
  X: [LetterElementType.CENTER_HORIZONTAL_ROTATE, LetterElementType.CENTER_HORIZONTAL_ROTATE_NEGATIVE],
  Y: [
    LetterElementType.LEFT_TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.CENTER_VERTICAL_BOTTOM_OFFSET,
    LetterElementType.RIGHT_TOP,
  ],
  Z: [LetterElementType.TOP, LetterElementType.CENTER_HORIZONTAL_ROTATE_NEGATIVE, LetterElementType.BOTTOM],
  '0': [LetterElementType.LEFT, LetterElementType.TOP, LetterElementType.BOTTOM, LetterElementType.RIGHT],
  '1': [LetterElementType.TOP_LEFT, LetterElementType.CENTER_VERTICAL, LetterElementType.BOTTOM],
  '2': [
    LetterElementType.LEFT_BOTTOM,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT_TOP,
  ],
  '3': [
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL_RIGHT,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT,
  ],
  '4': [LetterElementType.LEFT_TOP, LetterElementType.CENTER_HORIZONTAL, LetterElementType.RIGHT],
  '5': [
    LetterElementType.LEFT_TOP,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT_BOTTOM,
  ],
  '6': [
    LetterElementType.LEFT,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT_BOTTOM,
  ],
  '7': [LetterElementType.TOP, LetterElementType.RIGHT],
  '8': [
    LetterElementType.LEFT,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT,
  ],
  '9': [
    LetterElementType.LEFT_TOP,
    LetterElementType.TOP,
    LetterElementType.CENTER_HORIZONTAL,
    LetterElementType.BOTTOM,
    LetterElementType.RIGHT,
  ],
};

export const LetterMonogramMobile = {
  ...LetterMonogram,
  D: [LetterElementType.LEFT, LetterElementType.TOP_SHORT, LetterElementType.BOTTOM_SHORT, LetterElementType.RIGHT],
};
