import { createGlobalStyle } from 'styled-components';

import { DISABLE_ACCESSIBILITY_CLASS } from '@utils/constants';

import { COLORS } from './colors';
import { media } from './media';
import { FONT_FAMILY, LETTER_SPACING, typography } from './font';

export const GlobalStyle = createGlobalStyle`
  html {
    min-height: var(--vh);
  }

  body {
    ${typography({})};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;

    ${media.mobile} {
      ${typography({ spacing: LETTER_SPACING.M })};
    }
  }
  
  body.${DISABLE_ACCESSIBILITY_CLASS} * {
      outline: none;
  }

  input {
    color: currentColor;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${COLORS.BLACK};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  select, option {
    font: -moz-pull-down-menu;
  }

  .gm-style-iw-c {
    border-radius: 0!important;
    border:0!important;
    font-family: ${FONT_FAMILY.PRIMARY}!important;
    font-size:15px!important;
    width: 270px!important;
    box-shadow: none!important;
  }

  .gm-style-iw-t::after {
    display: none!important;
    opacity: 0!important;
  }
  
`;
