import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import api from '@services/api';
import {
  BookShippingData,
  FetchOrderRequest,
  FetchPayload,
  GetOrderDetailData,
  RemoveImageData,
  SetEditionData,
  SetOrderStatus,
  SORT_DIRECTION,
  SORT_TYPES,
  UploadImagesData,
} from '@definitions/order.types';
import reportError from '@utils/reportError';

const PREFIX = 'ORDERS';

const getApiOrderDetailsUrl = (data: GetOrderDetailData) =>
  data.variantId
    ? `/orders/${data.orderId}/products/${data.artworkId || ''}/variants/${data.variantId}/${data.quantityIndex}/`
    : `/orders/${data.orderId}/products/${data.artworkId || ''}/${data.quantityIndex}/`;

export const fetchOrders = createAsyncThunk<FetchPayload, FetchOrderRequest>(
  `${PREFIX}/FETCH_ORDERS`,
  async ({
    page = 1,
    notShipped = true,
    shipped = true,
    direction = SORT_DIRECTION.DESC,
    sort = SORT_TYPES.ID,
    selectedGalleries = [],
    orderId = '',
  }) => {
    const { data } = await api.get('/orders/', {
      params: { page, notShipped, shipped, sort, direction, selectedGalleries, orders_ids: orderId },
    });
    return { ...data, page, notShipped, shipped, direction, sort, selectedGalleries, orderId };
  }
);

export const confirmBooking = createAsyncThunk(
  `${PREFIX}/CONFIRM_SHIPPING`,
  async ({ orderId, artworkId, variantId, quantityIndex, fedexDate }: BookShippingData) => {
    try {
      await api.post(getApiOrderDetailsUrl({ orderId, artworkId, variantId, quantityIndex }) + 'confirm-shipping/', {
        fedexDate,
      });
    } catch (e: unknown) {
      const error = e as AxiosError;
      if (error.response?.data) {
        reportError(error.response.data.error);
      }
    }
  }
);

export const setEdition = createAsyncThunk(
  `${PREFIX}/SET_EDITION`,
  async ({ orderId, artworkId, variantId, quantityIndex, edition }: SetEditionData) => {
    await api.patch(getApiOrderDetailsUrl({ orderId, artworkId, variantId, quantityIndex }), { edition });

    return { edition, orderId };
  }
);

export const setOrderStatus = createAsyncThunk(
  `${PREFIX}/SET_STATUS`,
  async ({ orderId, artworkId, variantId, quantityIndex, shippingStatus }: SetOrderStatus) => {
    await api.patch(getApiOrderDetailsUrl({ orderId, artworkId, variantId, quantityIndex }), { shippingStatus });

    return { status, orderId };
  }
);

export const fetchOrderDetail = createAsyncThunk(`${PREFIX}/FETCH_ORDER_DETAIL`, async (data: GetOrderDetailData) => {
  const { data: order } = await api.get(getApiOrderDetailsUrl(data));
  return order;
});

export const uploadImages = createAsyncThunk(
  `${PREFIX}/UPLOAD_IMAGES`,
  async ({ orderId, artworkId, variantId, quantityIndex, file }: UploadImagesData) => {
    const formData = new FormData();
    formData.append('files', (file as unknown) as string);

    await api.post(getApiOrderDetailsUrl({ orderId, artworkId, variantId, quantityIndex }) + 'images/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
);

export const removeImage = createAsyncThunk(
  `${PREFIX}/REMOVE_IMAGE`,
  async ({ id, artworkId, orderId, variantId, quantityIndex }: RemoveImageData) => {
    await api.delete(getApiOrderDetailsUrl({ orderId, artworkId, variantId, quantityIndex }) + `images/${id}/`);
    fetchOrderDetail({ artworkId, orderId, variantId, quantityIndex });
  }
);
