import { createSelector } from '@reduxjs/toolkit';

import { GalleryDocumentsState } from '@definitions/galleryDocuments.types';

import { GlobalState } from '../createStore';

export const galleryDocumentsDomain = (state: GlobalState) => state.galleryDocuments;

export const selectLoading = createSelector(galleryDocumentsDomain, (state: GalleryDocumentsState) => state.isLoading);
export const selectArtworks = createSelector(
  galleryDocumentsDomain,
  (state: GalleryDocumentsState) => state.autocompleteArtworks
);
export const selectShippingMethods = createSelector(
  galleryDocumentsDomain,
  (state: GalleryDocumentsState) => state.shippingMethods
);
export const selectOrderNumbers = createSelector(
  galleryDocumentsDomain,
  (state: GalleryDocumentsState) => state.orderNumbers
);
export const selectExportId = createSelector(galleryDocumentsDomain, (state: GalleryDocumentsState) => state.exportId);
export const selectExportStatus = createSelector(
  galleryDocumentsDomain,
  (state: GalleryDocumentsState) => state.exportStatus
);
