import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { FetchFilterOptionsPayload, FiltersState } from '@definitions/filters.types';

import * as actions from './filters.actions';

export const initialState: FiltersState = {
  loading: false,
  filterOptions: [],
};

const handleFetchedFilterOptions = (state: FiltersState, { payload }: PayloadAction<FetchFilterOptionsPayload>) => {
  state.loading = false;
  state.filterOptions = payload.filterOptions;
};

const handleFetchedTradeFilterOptions = (
  state: FiltersState,
  { payload }: PayloadAction<FetchFilterOptionsPayload>
) => {
  state.loading = false;
  state.filterOptions = payload.filterOptions;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchFilterOptions.fulfilled, handleFetchedFilterOptions);
  builder.addCase(actions.fetchTradeFilterOptions.fulfilled, handleFetchedTradeFilterOptions);
});
