import { defineMessages } from 'react-intl';

export const cookieMessages = defineMessages({
  back: {
    id: 'cookieBanner.back',
    defaultMessage: 'Back',
  },
  expand: {
    id: 'cookieBanner.expand',
    defaultMessage: 'Expand',
  },
  collapse: {
    id: 'cookieBanner.collapse',
    defaultMessage: 'Collapse',
  },
  allow: {
    id: 'cookieBanner.allow',
    defaultMessage: 'Allow',
  },
  allowCookies: {
    id: 'cookieBanner.allowCookies',
    defaultMessage: 'Allow all cookies',
  },
  decline: {
    id: 'cookieBanner.decline',
    defaultMessage: 'Decline',
  },
  strictlyNecessaryCookiesTitle: {
    id: 'cookieBanner.strictlyNecessaryCookiesTitle',
    defaultMessage: 'Strictly necessary cookies',
  },
  strictlyNecessaryCookiesDescription: {
    id: 'cookieBanner.strictlyNecessaryCookiesDescription',
    defaultMessage:
      'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
  },
  performanceCookiesTitle: { id: 'cookieBanner.performanceCookiesTitle', defaultMessage: 'Performance cookies' },
  performanceCookiesDescription: {
    id: 'cookieBanner.performanceCookiesDescription',
    defaultMessage:
      'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.',
  },
  otherCookiesTitle: { id: 'cookieBanner.otherCookiesTitle', defaultMessage: 'Other cookies' },
  otherCookiesDescription: {
    id: 'cookieBanner.otherCookiesDescription',
    defaultMessage:
      'These cookies help us show you the most relevant marketing messages and track the performance of our marketing channels. These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
  },
});
