import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@services/api';
import { GlobalState } from '@modules/createStore';
import { GiftCertificate } from '@definitions/giftCertificate.types';

const PREFIX = 'GIFT_CERTIFICATE';

export const checkBalance = createAsyncThunk<GiftCertificate, string, { state: GlobalState }>(
  `${PREFIX}/CHECK_BALANCE`,
  async (code) => {
    const { data } = await api.post('/check-balance/', { code });

    return data;
  }
);
